import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const navigate = useNavigate();

  const handlePayment = () => {
    alert("Payment Successful!");
    localStorage.removeItem("checkoutData");
    navigate("/");
  };

  return (
    <div className="payment-container">
      <h2>Payment Page</h2>
      <p>Choose your payment method:</p>
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PaymentPage;

import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import payments_footer from "../images/payments.png";
import Header from "../components/header";
import Footer from "../components/footer";
import { useCart } from "./cartcontext";
import Preloader from "../components/preloader";

export default function Checkout() {
  const { cartItems, setCartItems } = useCart();
  const [subtotal, setSubtotal] = useState(0);
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [userToken] = useState(localStorage.getItem("accessToken"));
  const [checkoutItems, setCheckoutItems] = useState([]);
  const currentLocation = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const shippingAmount = 0;
  const tax = 0;
  const discount = 0;

  useEffect(() => {
    if (currentLocation.state?.buyNowProduct) {
      setCheckoutItems([currentLocation.state.buyNowProduct]);
    } else {
      const selectedCartData = JSON.parse(localStorage.getItem("selectedItems")) || [];
    if (selectedCartData.length > 0) {
      setCheckoutItems(selectedCartData);
    } else {
      setCheckoutItems(cartItems);
    }
    }
  }, [currentLocation.state, cartItems]);

  useEffect(() => {
    const calculatedSubtotal = checkoutItems.reduce((total, item) => {
      const itemQuantity = item.quantity || 1; // Ensure quantity is treated as 1 if missing
      return total + item.price * itemQuantity;
    }, 0);
    setSubtotal(calculatedSubtotal);
  }, [checkoutItems]);

  const calculateFinalAmount = (subtotal) => {
    if (!subtotal) return "0.00";
    const taxAmount = (subtotal * tax) / 100;
    const discountAmount = (subtotal * discount) / 100;
    const finalAmount = subtotal + shippingAmount + taxAmount - discountAmount;
    return finalAmount.toFixed(2);
  };

  // Form validation
  const handleAddressChange = (e) => {
    const value = e.target.value;
    setAddress(value);
    setError((prev) => ({ ...prev, address: value ? '' : 'Address is required' }));
  };
  
  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    setError((prev) => ({
      ...prev,
      mobile: value.match(/^\d{10}$/) ? '' : 'Mobile number must be 10 digits',
    }));
  };
  
  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);
    setError((prev) => ({ ...prev, city: value ? '' : 'City is required' }));
  };
  
  const handleStateChange = (e) => {
    const value = e.target.value;
    setState(value);
    setError((prev) => ({ ...prev, state: value ? '' : 'State is required' }));
  };
  
  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);
    setError((prev) => ({
      ...prev,
      pincode: value.match(/^\d{6}$/) ? '' : 'Pincode must be 6 digits',
    }));
  };
  const validateForm = () => {
    let errors = {};
    if (!address) errors.address = "Address is required";
    if (!mobile || isNaN(mobile) || mobile.length < 10) errors.mobile = "Valid mobile number is required";
    if (!mobile.match(/^\d{10}$/)) errors.mobile = 'Mobile number must be 10 digits';
    if (!city) errors.city = "City is required";
    if (!state) errors.state = "State is required";
    if (!pincode || isNaN(pincode) || pincode.length < 6) errors.pincode = "Valid pincode is required";
    if (!pincode.match(/^\d{6}$/)) errors.pincode = 'Pincode must be 6 digits';
    setError(errors);
    return Object.keys(errors).length === 0;
  };
  
  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      for (const item of checkoutItems) {
        const orderPayload = {
          product_id: item.id,
          quantity: item.quantity,
          amount: item.price,
          total_amount: item.price * item.quantity,
          mobile,
          address,
          city,
          state,
          pincode,
        };

        const response = await fetch('https://37h.4ad.mytemp.website/gsg/api/user/checkout/', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(orderPayload),
        });

        const rawResponse = await response.text();
        const data = response.headers.get("content-type")?.includes("application/json")
          ? JSON.parse(rawResponse)
          : null;

        if (response.status === 201) {
          if (!currentLocation.state?.buyNowProduct) {
            setCartItems([]);
            navigate("/ordersuccess");
          } else {
            navigate("/ordersuccess");
          }
        } else if (response.status === 400 && data?.error) {
          setModalMessage(data.error);
          setShowModal(true);
        } else if (response.status === 500) {
          setModalMessage("We are experiencing an issue at the moment. Our team has been notified and is working on fixing this issue. Please try again later.");
          setShowModal(true);
        } else {
          setError(data?.error || "There was an issue with your order. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
      setError("An error occurred. Please check your connection and try again.");
    }
  };

  return (
    <>
    <Preloader></Preloader>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bread-inner">
                <ul className="bread-list">
                  <li><Link to="/">Back to Home &nbsp; |<i className="ti-arrow-right"></i></Link></li>
                  <li className="active"><a href="#">Checkout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="shop checkout section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="checkout-form">
                <h2>Please enter required details</h2>
                <form className="form">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label>Address<span>*</span></label>
                        <textarea
                          name="address"
                          placeholder="Address"
                          className="address-txt"
                          value={address}
                          onChange={handleAddressChange}
                          required
                        ></textarea>
                        {error.address && <p className="error">{error.address}</p>}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label>Mobile No.<span>*</span></label>
                        <input
                          type="number"
                          name="mobile"
                          placeholder="Contact No."
                          value={mobile}
                          onChange={handleMobileChange}
                          required
                        />
                        {error.mobile && <p className="error">{error.mobile}</p>}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label>City<span>*</span></label>
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          value={city}
                          onChange={handleCityChange}
                          required
                        />
                        {error.city && <p className="error">{error.city}</p>}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label>State<span>*</span></label>
                        <input
                          type="text"
                          name="state"
                          placeholder="State"
                          value={state}
                          onChange={handleStateChange}
                          required
                        />
                        {error.state && <p className="error">{error.state}</p>}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label>Pincode<span>*</span></label>
                        <input
                          type="number"
                          name="pincode"
                          placeholder="Pincode"
                          value={pincode}
                          onChange={handlePincodeChange}
                          required
                        />
                        {error.pincode && <p className="error">{error.pincode}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <button type="submit" className="btn" onClick={handlePlaceOrder}>
                      Place Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="order-details">
                <div className="single-widget">
                  <h2>Cart Details</h2>
                  <div className="content">
                    <ul>
                      {checkoutItems.map((item, index) => (
                        <li key={index}>
                          <strong>Product Name:</strong> {item.name}<br />
                          <strong>Qty:</strong> {item.quantity} <br />
                          <strong> Price:</strong> Rs.{item.price} <br />
                          <strong> Total:</strong> Rs.{!isNaN(item.price) && !isNaN(item.quantity) ? (Number(item.price) * Number(item.quantity)).toFixed(2) : '0.00'}
                          {/* <strong> Total:</strong> Rs.{calculateTotal()} */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="single-widget">
                  <h2>Order Summary</h2>
                  <div className="content">
                    <ul>
                      <li>Sub Total<span>Rs.{subtotal.toFixed(2) || 0.00}</span></li>
                      <li>(+) Shipping<span>Rs.{shippingAmount}</span></li>
                      <li>(+) Tax<span>{tax}%</span></li>
                      <li>(+) Discount<span>{discount}%</span></li>
                      <li className="last">Grand Total<span>Rs.{calculateFinalAmount(subtotal)}</span></li>
                    </ul>
                  </div>
                </div>
                <div className="single-widget payement">
                  <div className="content">
                    <img src={payments_footer} alt="payments" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <div className="checkout-modal-overlay">
          <div className="checkout-modal-content d-flex flex-column align-items-center">
            <h2>Alert!</h2>
            <p>{modalMessage}</p>
            <button onClick={() => setShowModal(false)}>OK</button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}



import Footer from "./footer";
import Header from "./header";
import { Link, useParams } from "react-router-dom";
import Shopservices from "./shopservices";
import { useEffect, useState } from "react";
import axios from "axios";

const Trackorder = () => {
  const { orderId } = useParams();
  const [trackingData, setTrackingData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTrackingDetails = async () => {
      try {
        const userToken = localStorage.getItem("accessToken");
        if (!userToken) {
          console.error("No user token found");
          return;
        }

        console.log("Fetching tracking for Order ID:", orderId);
        const response = await fetch(`https://37h.4ad.mytemp.website/gsg/api/order/tracking/${orderId}/`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching tracking: ${response.status}`);
        }

        const result = await response.json();

        if (result.success && result.data) {
          setTrackingData(result.data);
        } else {
          setError("No tracking data found for this order.");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    if (orderId) {
      fetchTrackingDetails();
    }
  }, [orderId]);

  return (
    <>
      <Header />
        {/* Breadcrumbs */}
                            <div className="breadcrumbs">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="bread-inner">
                                      <ul className="bread-list">
                                        <li>
                                          <Link to="/orderhistory">
                                            Back to Orders &nbsp; |<i className="ti-arrow-right"></i>
                                          </Link>
                                        </li>
                                        <li className="active"><a href="javascript:void(0);">Track Orders</a></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* End Breadcrumbs */}
      <div className="container py-4">
        <h2 className="mb-4 text-center">Track Order</h2>
        {error && <p className="text-danger text-center">{error}</p>}
        <div className="table-responsive">
          <table className="table table-hover align-middle">
            <thead className="table-light">
              <tr>
                <th>Order ID</th>
                <th>Courier Name</th>
                <th>Tracking Number</th>
                <th>Booked Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {trackingData ? (
                <tr align="center">
                  <td>{trackingData[0].order_id}</td>
                  <td>{trackingData[0].courier_name}</td>
                  <td>{trackingData[0].tracking_number}</td>
                  <td>{trackingData[0].booked_date}</td>
                  <td>
                    <span className="badge bg-success">{trackingData[0].status}</span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No tracking data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Trackorder;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Confetti from "react-confetti"; // Install this library with: npm install react-confetti
import { CheckCircle } from 'lucide-react'; // Optional icon library

export default function OrderSuccess() {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Stop confetti after 5 seconds
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header />
      {showConfetti && <Confetti numberOfPieces={300} />}
      <section className="order-success section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="order-success-content text-center">
                <div className="success-img-div">
                  <CheckCircle className="success-icon animate-tick" color="green" size={100} />
                </div>
                <h2 className="animate-text">Order Placed Successfully!</h2>
                <p className="animate-description">
                  Thank you for your order. Your order is being processed and will be shipped soon.
                </p>
                <div className="order-actions">
                  <Link to="/" className="btn back-to-home-btn">Continue Shopping</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .order-success {
          margin-bottom: 50px;
        }
      `}</style>
      <Footer />
    </>
  );
}

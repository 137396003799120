import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginRequiredPopup = ({ show, setShow }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  const handleGoToLogin = () => {
    setShow(false);  // Hide the modal before navigating
    navigate("/login");  // Proceed with navigation
  };

  if (!isVisible) return null;

  return (
    <div className="custom-popup-overlay">
      <div className="custom-popup">
        <div className="popup-header">
          <h2>Login Required</h2>
          <button className="close-button" onClick={() => setShow(false)}>&times;</button>
        </div>
        <div className="popup-body">
          <p>You need to log in to proceed with checkout.</p>
        </div>
        <div className="popup-footer">
          <button className="dark-btn" onClick={() => setShow(false)}>Close</button>
          <button className="continue-btn" onClick={handleGoToLogin}>Go to Login</button>
        </div>
      </div>
    </div>
  );
};

export default LoginRequiredPopup;
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../components/header";
import Preloader from "../components/preloader";
import nodatafound from "../images/nodata.png";
import Footer from "../components/footer";

const CategoryPage = () => {
    const { id } = useParams(); // Get category ID from the URL
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

  // Fetch products when categoryId changes
  useEffect(() => {
    axios
      .get(`https://37h.4ad.mytemp.website/gsg/api/product/?category=${id}`)
      .then((response) => {
        setProducts(response.data.results);
        setError(null); // Clear any previous error
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
        //setError(err.response?.status === 404 ? "Category not found." : "An error occurred.");
        setError("Failed to load products. Please try again.");
      });
  }, [id]);

  const handleNavigation = (product) => {
    // Navigate to viewproduct page and pass product details via state
    navigate(`/viewproduct/${product.id}`, { state: { product } });
  };

  return (
    <div>
     <Header></Header>
  
      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bread-inner">
                <ul className="bread-list">
                  <li>
                    <Link to="/" className="splitter">
                      Back to Home &nbsp;|<i className="ti-arrow-right"></i>
                    </Link>
                  </li>
                  <li className="active"><a href="#">Product Details</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumbs */}
  
      <div className="product-area section">
      <div className="container">
       <div className="row">
                 <div className="col-12">
                   <div className="product-info">
                     <div className="tab-content" id="myTabContent">
                       <div className="tab-pane fade show active">
                         <div className="row">
                    <div className="category-section">
                      <div className="row">
                      {products.length > 0 ? (
                         products.map((product) => (
                               <div className="col-xl-3 col-lg-4 col-md-4 col-12">
                                 <div className="card single-product">
                    <Link href="#" onClick={(e) => { e.preventDefault();
                               handleNavigation(product);
                               }}>
                                   <div className="product-img">
                                     <a href="#">
                                       <img
                                         className="card-img-top default-img"
                                         src={product.image}
                                         alt={product.name}
                                       />
                                       <img
                                         className="card-img-top hover-img"
                                         src={product.image}
                                         alt={product.name}
                                       />
                                     </a>
                                   </div>
                                   <div className="card-body">
                                     <div className="card-title product-content">
                      
                                       <h3>{product.name}</h3>
                                       <div className="product-price">
                                         <span>₹{product.price}</span>
                                       </div>
                    
                                     </div>
                                   </div>
                      </Link>
                                   </div>
                    
                                 </div>
                      ))
            ) : (
            //  
              // <p>No products available in this category.</p>
              <>
              {/* <Preloader></Preloader> */}
              <div className="no-results no-results-container">
              <img src={nodatafound} alt="No Results Found" className="no-results-image" />
              <p className="no-results-text">Oops! No results found".</p>
           </div>
           </>
            )}
                         </div>
                       </div>
                     </div>
            
                   </div>
                 </div>
               </div>
             </div>
           </div>
    </div>
    
    </div>
    <Footer></Footer>
    </div>
  
  );
  
};

export default CategoryPage;

import React,{useContext, useEffect, useState} from "react";
import mainlogo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCart } from "../categories/cartcontext";
import Slidenav from "./slidenav";
import { UserContext } from "../categories/contextapi";

export default function Header(){
	const [searchTerm, setSearchTerm] = useState("");
	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [categories, setCategories] = useState([]);
	const [userFirstName, setUserFirstName] = useState('');
  const { cartItems } = useCart();
   const { userEmail,setUserEmail  } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
	 useEffect(() => {
    // Retrieve userFirstName from localStorage when the component mounts
    const storedFirstName = localStorage.getItem('userFirstName');
    if (storedFirstName) {
      setUserFirstName(storedFirstName);
    }
  }, []);
	
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://37h.4ad.mytemp.website/gsg/api/category/");
        if (response.data && response.data.results) {
          setCategories(response.data.results);
        } else {
          console.error("Unexpected API structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleGoToLogin = () => {
    navigate("/login");
  };

  const handleSearch = (e) => {
	  e.preventDefault();
	  if (searchTerm.trim()) {
		navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
	  }
	};
	 const toggleDropdown = () => {
    console.log("Dropdown State:", isDropdownOpen);
    console.log("User FirstName:", userFirstName);
    console.log("Is Mobile:", isMobile);    
    // setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownOpen((prev) => {
      console.log("New State:", !prev);
      return !prev; // Reliable state update
    });
  };
  // const toggleDropdown = () => {
  //   console.log("Dropdown clicked");
  //   setIsDropdownOpen((prev) => !prev);
  // };
  const toggleCart = () => {
    navigate("/cartPage")
  };
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userFirstName');
    localStorage.removeItem('userEmail');
    setIsDropdownOpen(false);
    sessionStorage.clear();
    navigate('/'); 
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
    return(
        <>
      
        <header>
        <div class="header-top">
            <div class="container">
                <div class="header-contact">
                    <p> +91 88388 65256</p>
                    <p> | </p>
                    <p> gadgetsgosmart@gmail.com</p>
                </div>

                <div class="header-top-actions">
                <ul className="list-main">
							{userFirstName? (
								<li>
								<i className="fa fa-user-circle-o me-2" aria-hidden="true"></i>
								{/* Show user's email */}
							 <span>Welcome, {userFirstName }</span>
								</li>
							) : (
								<li>
								{/* <i className="ti-user"></i> */}
								<a href="javascript:void(0);" onClick={handleGoToLogin}>Login / Signup</a>
								</li>
							)}
							</ul>
                </div>
            </div>
        </div>

        <div class="header-main">
            <div class="container">
                <a href="/" class="header-logo">
                    <img src={mainlogo} alt="logo" width="200" height="36"></img></a>

                <div class="header-search-container">
                <form onSubmit={handleSearch} className="search-form">
                    <input type="search" name="search" class="search-field" placeholder="Search Products" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}></input>

                    <button class="search-btn"><ion-icon name="search-outline"></ion-icon></button>
                    </form>
                </div>
            
                <div class="header-user-actions">
                    <button className="action-btn single-icon" onClick={toggleDropdown}>
                        <ion-icon name="person-outline"></ion-icon>
                        </button>
                    {userFirstName && isDropdownOpen && (
                      <div className="dropdown-menu">
                        <ul>
                          <li><Link to="/orderhistory">My Orders</Link></li>
                          <li><Link to="/changepassword">Change Password</Link></li>
                          <li><a href="javascript:void(0);" onClick={handleLogout}>Logout</a></li>
                        </ul>
                      </div>
                    )}  
                    <button class="action-btn" onClick={toggleCart}>
                        <ion-icon name="bag-handle-outline"></ion-icon>
                        <span class="count">{cartItems.length}</span>
                    </button>
                </div>

            </div>
        </div>

        <nav class="desktop-navigation-menu">
            <div class="container">
                <ul class="desktop-menu-category-list">
                    <li class="menu-category">
                    <Link to="/" class="menu-title active">Home</Link>
                    </li>

                    <li class="menu-category">
                         <Link to="#" class="menu-title">Categories</Link>
                         <ul class="dropdown">
                                {categories.map((category, index) => (
                                    <li key={index} className="menu-category">
                                        <Link to={`/category/${index + 1}`} className="menu-title">{category.name}</Link>
                                      </li>
                                      ))}
                                      {!categories.length && <li>No Categories Available</li>}
                            </ul>
                    </li>
                    <li class="menu-category">
                         <Link to="/contact" class="menu-title">Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav>
        {isMobile && (
        <div class="mobile-bottom-navigation">
            <button class="action-btn" data-mobile-menu-open-btn onClick={() => setIsMenuOpen(true)}>
                <ion-icon name="menu-outline"></ion-icon>
            </button>

            <button class="action-btn">
                <ion-icon name="bag-handle-outline"></ion-icon>
                <span class="count">0</span>
            </button>

            <button className="action-btn" onClick={toggleDropdown}>
              <ion-icon name="person-outline"></ion-icon>
            </button>
            {userFirstName && isDropdownOpen && isMobile && (
  <div className="dropdown-menu" style={{ position: "absolute", zIndex: 9999 }}>
    <ul>
      <li><Link to="/my-orders">My Orders</Link></li>
      <li><button onClick={handleLogout}>Logout</button></li>
    </ul>
  </div>
)}

        </div>
)}
           {/* Render only on mobile and tablet */}
         <Slidenav isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </header>
       </>
    )
}
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [userFirstname, setUserFirstname] = useState(localStorage.getItem('userFirstname') || '');

  return (
    <UserContext.Provider value={{ userEmail, setUserEmail, userFirstname, setUserFirstname  }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";

const Slidenav = ({ isOpen, onClose }) => {
  const [categories, setCategories] = useState([]);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://37h.4ad.mytemp.website/gsg/api/category/");
        const data = response.data;
      // Extract results array if present
      if (data && Array.isArray(data.results)) {
        setCategories(data.results);
      } else {
        console.error('Unexpected API response:', data);
        setCategories([]);
      }
      } catch (error) {
      console.error('Error fetching categories:', error);
      }
      };

    fetchCategories();

       // Handle screen resize
   const handleResize = () => setIsMobile(window.innerWidth <= 768);
   window.addEventListener('resize', handleResize);

   return () => window.removeEventListener('resize', handleResize);
 }, []);
    // Accordion Toggle
    const accordionBtns = document.querySelectorAll('[data-accordion-btn]');
    const accordions = document.querySelectorAll('[data-accordion]');

    const handleAccordionClick = (btn, index) => {
      const isActive = btn.nextElementSibling.classList.contains('active');

      accordions.forEach((acc, i) => {
        if (isActive) return;
        acc.classList.remove('active');
        accordionBtns[i].classList.remove('active');
      });

      btn.nextElementSibling.classList.toggle('active');
      btn.classList.toggle('active');
    };

    accordionBtns.forEach((btn, index) => {
      btn.addEventListener('click', () => handleAccordionClick(btn, index));
    });

 if (!isOpen || !isMobile) return null;

  return (
    <div>
      {/* Mobile Menu */}
      <nav data-mobile-menu className="mobile-navigation-menu has-scrollbar active">
        <div className="menu-top">
          <h2 className="menu-title">Menu</h2>
          <button className="menu-close-btn" onClick={onClose}>
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <ul className="mobile-menu-category-list">
          <li className="menu-category"><Link to="/" className="menu-title">Home</Link></li>

          <li className="menu-category">
          <button className="accordion-menu" onClick={() => {
  setIsCategoryOpen(!isCategoryOpen);
  console.log('Category Open:', !isCategoryOpen);
}}>

              <p className="menu-title">Categories</p>
              <div>
              {/* <ion-icon name="chevron-down-outline" className="add-icon"></ion-icon>
              <ion-icon name="chevron-up-outline" className="remove-icon"></ion-icon> */}
               {isCategoryOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
              </div>
            </button>
     {isCategoryOpen && (
            <ul className="submenu-category-list">
              {categories.length > 0 ? (
                categories.map((category, index) => (
                  <li key={index} className="submenu-category">
                    <Link to={`/category/${index + 1}`} className="submenu-title">{category.name}</Link>
                  </li>
                ))
              ) : (
                <li>No Categories Available</li>
              )}
            </ul>
              )}
          </li>

          <li className="menu-category"><Link to="/contact" className="menu-title">Contact Us</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Slidenav;
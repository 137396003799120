import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import payments_footer from "../images/payments.png";
import { useCart } from "../categories/cartcontext";
import Shopservices from "./shopservices";
import LoginRequiredPopup from "../categories/modal_popup";

export default function ViewProduct() {
  const location = useLocation();
  const { id } = useParams();
  const [product, setProduct] = useState(location.state?.product || null); 
  const navigate = useNavigate();
  const { cartItems,addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
const [userToken, setUserToken] = useState(localStorage.getItem("accessToken"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
 const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUserToken(localStorage.getItem("accessToken"));
    setUserId(localStorage.getItem("userId"));
  }, []);

  useEffect(() => {
    if (!product) {
      // Simulate a fetch call based on the product ID
      const fetchProduct = async () => {
        const productData = await fakeFetchProductById(id);
        setProduct(productData);
      };
      fetchProduct();
    }
  }, [id, product]);  
  
  // Simulated fetch function (replace with your API call)
  const fakeFetchProductById = async (productId) => {
    return {
      id: productId,
      name: `Product ${productId}`,
      image: "https://via.placeholder.com/150",
      price: 299,
      description: `This is a detailed description of product ${productId}.`,
    };
  };
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity > 0) {
      setQuantity(newQuantity); // Update quantity only if greater than 0
    }
  };

  const handleAddToCart = () => {
      addToCart({ ...product,quantity });
    console.log("Adding to Cart:", { 
      product_id: product.id, 
      name: product.name, 
      description: product.description, 
      price: product.price, 
      image: product.image, 
      quantity:quantity 
    });
    navigate('/cartPage')
  // };
}
const handleBuyNow = () => {
  if (!userToken || !userId) {
    setShowModal(true);
    return;
  }
  else{
    navigate("/checkoutPage", {
      state: { buyNowProduct: { ...product, quantity } },
    });
  }

};
  if (!product) {
    return <div>Loading...</div>; // Show loading state
  }
  const isProductInCart = cartItems.some((item) => item.id === product.id);

  return (
    <div className="container-fluid px-0">
      <Header />
       {/* Breadcrumbs */}
                  <div className="breadcrumbs">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="bread-inner">
                            <ul className="bread-list">
                              <li>
                                <Link to="/">
                                  Back to Home <i className="ti-arrow-right"></i>
                                </Link>
                              </li>
                              <li className="active"><a href="#">Product Details</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Breadcrumbs */}
                  <div className="product-view-container">
  <div className="product-details">
    <div className="product-grid">
            {/* First Column: Product Image */}
            <div className="product-image col-lg-5">
              <img src={product.image} alt={product.name} className="img-fluid" />
            </div>

            {/* Second Column: Product Details */}
            <div className="product-info col-lg-5">
              <h1>{product.name}</h1>
              <p className="price">
                <span className="old-price">{product.oldPrice}</span> ₹{product.price}
              </p>
              <p className="description">{product.description}</p>
              <div className="quantity-section">
                <label htmlFor="quantity">Qty: </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={quantity}
                  min="1"
                  defaultValue="1"
                  className="quantity-input"
                   onChange={handleQuantityChange} 
                />
              </div>

              {/* Add to Cart Button */}
             
            {isProductInCart ? (
        <button className="add-to-cart-btn" onClick={() => navigate("/checkoutPage")}>
          Place Order
        </button>
      ) : (
        <>
              <div className="product-action-2">
                <button onClick={handleBuyNow} className="add-to-cart-btn">
                 Buy Now
                </button>

                  <button onClick={handleAddToCart} className="add-to-cart-btn">
                  Add to Cart
                </button>
              </div>
            
              <div className="safe-checkout">
                <p>Guaranteed Safe Checkout</p>
                <img src={payments_footer} alt="Safe Checkout Icons" />
              </div>
              </>
      )}
            </div>
          </div>
        </div>
      </div>
      <Shopservices/>
      <Footer />
      <LoginRequiredPopup show={showModal} setShow={setShowModal}></LoginRequiredPopup>
    </div>
  );
};


import React, { useContext, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Header from './header';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../categories/contextapi';

const SignupPage = () => {
  const [emailStatus, setEmailStatus] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setUserEmail,setUserFirstname } = useContext(UserContext);

  const checkEmailExistence = async (email) => {
    try {
      const response = await axios.post('https://37h.4ad.mytemp.website/gsg/api/user/register/', { email });
    if (response.data.email && response.data.email.length > 0) {
      setEmailStatus('error');
      setEmailErrorMessage(response.data.email[0]);
    } 
    else if (response.status === 201) {
      setEmailStatus('success');
      setEmailErrorMessage('');
    }

  } catch (error) {
    if (error.response && error.response.status === 400) {
      setEmailStatus('error');
      setEmailErrorMessage(error.response.data.email ? error.response.data.email[0] : '');
    } 
    else if (error.response && error.response.status !== 400) {
      setEmailStatus('error');
      setEmailErrorMessage('An error occurred while checking the email. Please try again later.');
    }
  }
};

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required('First Name is required'),
    last_name: Yup.string()
      .required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
      phone: Yup.string()
      .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
 
      const response = await axios.post('https://37h.4ad.mytemp.website/gsg/api/user/register/', values);
      
      if (response.status === 201) {
        alert('Signup Successful!');
        localStorage.setItem('userEmail', values.email);
        localStorage.setItem('userFirstname', values.first_name);
        setUserEmail(values.email);
        setUserFirstname(values.first_name);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error during registration:', error);
  
      // Log detailed error response if available
      if (error.response) {
        console.error('Error Response Data:', error.response.data);
        alert(`Signup failed: ${error.response.data.message || 'Unknown error'}`);
      } else {
        alert('Signup failed. Please try again later.');
      }
    } finally {
      setSubmitting(false);
    }
  };
  
  const handleGoToLogin = () => {
    navigate("/login");
  };  

  return (
    <>
      <Header />
      <div className="signuppage-container">
        <div className="signup-form-container mt-2">
          <h2 className="form-header">Sign Up</h2>

          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              password: '',
              phone: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="form-grid">
                  {/* First Name */}
                  <div className="form-group">
                    <label htmlFor="first_name" className="form-label">
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="first_name"
                      name="first_name"
                      className="form-input"
                      placeholder="Enter your first name"
                    />
                    <ErrorMessage name="first_name" component="div" className="form-error" />
                  </div>

                  {/* Last Name */}
                  <div className="form-group">
                    <label htmlFor="last_name" className="form-label">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="last_name"
                      name="last_name"
                      className="form-input"
                      placeholder="Enter your last name"
                    />
                    <ErrorMessage name="last_name" component="div" className="form-error" />
                  </div>

                  {/* Email */}
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      className="form-input"
                      placeholder="Enter your email"
                      onBlur={(e) => {
                        setFieldValue('email', e.target.value);
                        checkEmailExistence(e.target.value);  // Check email existence on blur
                      }}
                    />
                    <ErrorMessage name="email" component="div" className="form-error" />
                    {emailStatus === 'error' && <div className="form-error">{emailErrorMessage}</div>}
                    {emailStatus === 'success' && <i className="success-icon fas fa-check-circle"></i>}
                  </div>

                  {/* Password */}
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="form-input"
                      placeholder="Enter your password"
                    />
                    <ErrorMessage name="password" component="div" className="form-error" />
                  </div>

                  {/* Phone No */}
                  <div className="form-group full-width">
                    <label htmlFor="phone" className="form-label">
                      Contact No
                    </label>
                    <Field
                      type="text"
                      id="phone"
                      name="phone"
                      className="form-input"
                      placeholder="Contact No."
                    />
                    <ErrorMessage name="phone" component="div" className="form-error" />
                  </div>
                </div>

                <button
                  type="submit"
                  className="form-submit-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                </button>
              </Form>
            )}
          </Formik>
          <p className="form-footer">
            Already have an account? <a href="javascript:void(0);" onClick={handleGoToLogin}>Sign In</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SignupPage;

import React, { useEffect, useState } from "react";
import Header from "./header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./footer";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [apiError, setApiError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const userToken = localStorage.getItem("accessToken");
        if (!userToken) {
          console.error("No user token found");
          return;
        }

        const response = await fetch("https://37h.4ad.mytemp.website/gsg/api/user/order/", {  
          method: 'POST',
          headers: { 
            "Authorization": `Bearer ${userToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({})
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const result = await response.json();
  
        if (result.success && Array.isArray(result.data)) {
          setOrders(result.data);
        } else {
          console.error("Unexpected API response format:", result);
        }
  
      } catch (error) {
        console.error("Error fetching orders:", error.message);
      }
    };

    fetchOrders();
  }, []);
   // Calculate pagination variables
   const totalPages = Math.ceil(orders.length / ordersPerPage);
   console.log(totalPages);
   const indexOfLastOrder = currentPage * ordersPerPage;
   const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
   const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

      const handleDownloadInvoice = (orderId) => {
        alert(`Downloading invoice for Order #${orderId}`);
      };

      const handletrackorder = (orderId) => {
        navigate(`/trackorder/${orderId}`);
      }
      const getStatusBadge = (status) => {
        switch (status) {
          case "Pending":
            return "badge bg-warning text-dark";
          case "Confirmed":
            return "badge bg-primary";
          case "Shipped":
            return "badge bg-info text-dark";
          case "Delivered":
            return "badge bg-success";
          case "Cancelled":
            return "badge bg-danger";
          case "Returned":
            return "badge bg-secondary";
          default:
            return "badge bg-dark";
        }
      };

  return (
    <>
    <Header/>
        <div className="breadcrumbs">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bread-inner">
                  <ul className="bread-list">
                    <li>
                      <Link to="/">
                        Back to Home &nbsp; |<i className="ti-arrow-right"></i>
                      </Link>
                    </li>
                    <li className="active"><a href="javascript:void(0);">My Orders</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    <div className="container py-4">
      <h2 className="mb-4 text-center">Order History</h2>
      {apiError && <p className="error-message">{apiError}</p>}
      <div className="table-responsive">
        <table className="table table-hover align-middle">
          <thead className="table-light">
            <tr>
              <th>Order ID</th>
              <th>Product</th>
              <th>Date</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th colSpan={2} align="center">Actions</th>
            </tr>
          </thead>
          <tbody>
  {currentOrders.length > 0 ? (
    currentOrders.map((order) => (
      <tr key={order.id} align="center">
        <td>{order.id}</td>
        <td>
          <div className="d-flex align-items-center">
            <img src={`https://37h.4ad.mytemp.website/gsg/media/${order.product__image}`} className="product-img me-2" alt={order.product__name} style={{ width: "50px", height: "50px" }} />
            <div>
              <strong>{order.product__name}</strong>
            </div>
          </div>
        </td>
        <td>{new Date(order.created_at).toLocaleDateString()}</td>
        <td>${order.total_amount}</td>
        <td>
          <span className={getStatusBadge(order.status)}>{order.status}</span>
        </td>
        <td>
          {["Confirmed", "Shipped", "Delivered", "Returned"].includes(order.status) && (
            <button className="btn btn-sm bg-success-subtle text-dark ms-2" onClick={() => handletrackorder(order.id)}>
              <i className="fa fa-map-marker"></i> Track Order
            </button>
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="6" className="text-center">No orders found</td>
    </tr>
  )}
</tbody>

        </table>
      </div>
      {totalPages > 1 && (
  <div className="d-flex justify-content-center mt-4">
    <nav>
      <ul className="pagination">
        {/* Previous Button */}
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>← Previous</button>
        </li>

        {/* Page Numbers */}
        {[...Array(totalPages)].map((_, index) => (
          <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
            <button className="page-link" style={currentPage === index + 1 ? { backgroundColor: "#dc3545", color: "white", borderColor: "#dc3545" } : {}} onClick={() => setCurrentPage(index + 1)}>
              {index + 1}
            </button>
          </li>
        ))}

        {/* Next Button */}
        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next →</button>
        </li>
      </ul>
    </nav>
  </div>
)}
    </div>
    
    <Footer/>
    </>
  );
};

export default OrderHistory;

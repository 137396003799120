import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import mainlogo from "../images/logo.png";

const ResetPassword = () => {

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
  });

  return (
    <div className="reset-pwd-container">
      <div className="reset-pwd-box">
            <div className="mb-3 d-flex justify-content-center">
            <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
            </div>
        <h2>Reset Password</h2>
        <Formik
          initialValues={{ oldPassword: "", newPassword: "" }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="oldPassword">Old Password</label>
                <Field type="password" id="oldPassword" name="oldPassword" className="form-input" />
                <ErrorMessage name="oldPassword" component="div" className="form-error" />
              </div>

              <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <Field type="password" id="newPassword" name="newPassword" className="form-input" />
                <ErrorMessage name="newPassword" component="div" className="form-error" />
              </div>

              <button type="submit" className="form-submit-btn" disabled={isSubmitting}>
                {isSubmitting ? "Changing..." : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;

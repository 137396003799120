import React from "react";
import payments_footer from "../images/payments.png";
import footerlogo from "../images/footer-logo.jpg";

export default function Footer(){
    return(
        <>
        {/* <!-- Start Footer Area --> */}
	<footer className="footer">
		{/* <!-- Footer Top --> */}
		<div className="footer-top section">
			<div className="container">
				<div className="row">
					<div className="col-lg-5 col-md-6 col-12">
						{/* <!-- Single Widget --> */}
						<div className="single-footer about">
							<div className="logo">
								<a href="index.html"><img src={footerlogo} className="img-fluid w-75" alt="#"></img></a>
							</div>
							{/* <!-- <p className="text">Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue,  magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p> --> */}
							<p className="call">Got Question? Call us 24/7<span><a href="#">+91 88388 65256</a></span></p>
						</div>
						{/* <!-- End Single Widget --> */}
					</div>
					<div className="col-lg-2 col-md-6 col-12">
						{/* <!-- Single Widget --> */}
						<div className="single-footer links">
							<h4>Information</h4>
							<ul>
								<li><a href="#">About Us</a></li>
								<li><a href="#">Faq</a></li>
								<li><a href="#">Terms & Conditions</a></li>
								<li><a href="#">Contact Us</a></li>
								<li><a href="#">Help</a></li>
							</ul>
						</div>
						{/* <!-- End Single Widget --> */}
					</div>
					<div className="col-lg-2 col-md-6 col-12">
						{/* <!-- Single Widget --> */}
						<div className="single-footer links">
							<h4>Customer Service</h4>
							<ul>
								<li><a href="#">Payment Methods</a></li>
								<li><a href="#">Money-back</a></li>
								<li><a href="#">Returns</a></li>
								<li><a href="#">Shipping</a></li>
								<li><a href="#">Privacy Policy</a></li>
							</ul>
						</div>
						{/* <!-- End Single Widget --> */}
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						{/* <!-- Single Widget --> */}
						<div className="single-footer social">
							<h4>Get In Touch</h4>
							{/* <!-- Single Widget --> */}
							<div className="contact">
								<ul>
									<li>D.no A40/7, TNBH Colony,</li>
									<li>Bagalur Road, Hosur, 635109</li>
									<li>gadgetsgosmart@gmail.com</li>
									<li>+91 88388 65256</li>
								</ul>
							</div>
							{/* <!-- End Single Widget --> */}
							<ul>
								<li><a href="#"><i className="ti-facebook"></i></a></li>
								<li><a href="#"><i className="ti-instagram"></i></a></li>
							</ul>
						</div>
						{/* <!-- End Single Widget --> */}
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Footer Top --> */}
		<div className="copyright">
			<div className="container">
				<div className="inner">
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="left">
								<p>Copyright © 2024 <a href="#" target="_blank"></a>  -  All Rights Reserved.</p>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="right">
								<img src={payments_footer} alt="#"></img>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
	{/* <!-- /End Footer Area --> */}
        </>
    )
}
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import mainlogo from "../images/logo.png";

const ForgotPassword = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Password reset request for:", values.email);
    setTimeout(() => {
      alert("Password reset link sent to your email!");
      setSubmitting(false);
    }, 1000);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="col-md-6 col-lg-4 text-center">
        {/* Company Logo */}
        <div className="mb-3 d-flex justify-content-center">
          <img src={mainlogo} alt="Company Logo" className="img-fluid" style={{ maxWidth: "200px" }} />
        </div>
        <h3 className="fw-bold mb-3">Did you forget your password?</h3>
        <p className="text-muted mb-4">
          Enter your email address below and we'll send you a password reset link.
        </p>

        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-100">
              <div className="mb-3 text-start">
                <label htmlFor="email" className="form-label fw-bold">Email Address</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="you@example.com"
                />
                <ErrorMessage name="email" component="div" className="text-danger mt-1" />
              </div>

              <button
                type="submit"
                className="btn btn-dark w-100"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Processing..." : "Request Reset Link"}
              </button>
            </Form>
          )}
        </Formik>

        <div className="mt-3">
          <Link to="/login" className="text-decoration-underline">Back to Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

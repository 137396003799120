import React, { useEffect } from "react";

export default function Preloader() {
    useEffect(() => {
        // Set timeout for the preloader fadeOut and body class removal
        const preloaderTimeout = setTimeout(() => {
            // Fade out the preloader (you can manage this with CSS or jQuery as needed)
            const preloader = document.querySelector('.preloader');
            if (preloader) {
                preloader.style.transition = 'opacity 1s ease';
                preloader.style.opacity = '0';  // This triggers fadeOut effect
            }

            // After the fadeOut, remove the preloader from DOM after 2 seconds
            setTimeout(() => {
                preloader && preloader.remove();  // Remove preloader after fadeOut
            }, 1000);  // Match the fadeOut duration

            // Remove the no-scroll class after the preloader time
            document.body.classList.remove('no-scroll');
        }, 2000);  // Preloader stays visible for 2 seconds

        // Cleanup function to clear timeout if the component unmounts
        return () => clearTimeout(preloaderTimeout);
    }, []);

    return (
        <>
            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </>
    );
}

import React from "react";
import Header from "./header";
import { Link } from 'react-router-dom';
import Footer from "./footer";

export default function Contact(){
    return(
        <>
        <Header></Header>
         {/* <!-- Breadcrumbs --> */}
	<div className="breadcrumbs">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="bread-inner">
						<ul className="bread-list">
							<li><Link to="/"> Back to Home &nbsp;|<i className="ti-arrow-right"></i></Link></li>
							<li className="active"><a href="#">Contact</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/* <!-- End Breadcrumbs --> */}
    {/* <!-- Start Contact --> */}
	<section id="contact-us" class="contact-us section">
		<div class="container">
				<div class="contact-head">
					<div class="row">
						<div class="col-lg-8 col-12">
							<div class="form-main">
								<div class="title">
									<h4>Get in touch</h4>
									<h3>Write us a message</h3>
								</div>
								<form class="form" method="post" action="mail/mail.php">
									<div class="row">
										<div class="col-lg-6 col-12">
											<div class="form-group">
												<label>Your Name<span>*</span></label>
												<input name="name" type="text" placeholder=""></input>
											</div>
										</div>
										<div class="col-lg-6 col-12">
											<div class="form-group">
												<label>Subject<span>*</span></label>
												<input name="subject" type="text" placeholder=""></input>
											</div>
										</div>
										<div class="col-lg-6 col-12">
											<div class="form-group">
												<label>Email ID<span>*</span></label>
												<input name="email" type="email" placeholder=""></input>
											</div>	
										</div>
										<div class="col-lg-6 col-12">
											<div class="form-group">
												<label>Mobile No.<span>*</span></label>
												<input name="company_name" type="text" placeholder=""></input>
											</div>	
										</div>
										<div class="col-12">
											<div class="form-group message">
												<label>Message<span>*</span></label>
												<textarea name="message" placeholder=""></textarea>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group button">
												<button type="submit" class="btn ">Send Message</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="col-lg-4 col-12">
							<div class="single-head">
								<div class="single-info">
									<i class="fa fa-phone"></i>
									<h4 class="title">Call us Now:</h4>
									<ul>
										<li>+91 88388 65256</li>
										{/* <li>+91 88388 65256</li> */}
									</ul>
								</div>
								<div class="single-info">
									<i class="fa fa-envelope-open"></i>
									<h4 class="title">Email:</h4>
									<ul>
										<li><a href="mailto:gadgetsgosmart@gmail.com">gadgetsgosmart@gmail.com</a></li>
									</ul>
								</div>
								<div class="single-info">
									<i class="fa fa-location-arrow"></i>
									<h4 class="title">Our Address:</h4>
									<ul>
										<li>D.no A40/7, TNBH Colony,
											Bagalur Road, Hosur, 635109
											gadgetsgosmart@gmail.com
											+91 88388 65256</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	</section>
	{/* <!--/ End Contact -->
	
	<!-- Map Section --> */}
	<div class="map-section">
		<div id="myMap">
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12992.310622677109!2d77.8315845!3d12.7386807!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae712b5864323f%3A0x84b7045d03bc643c!2sTamilnadu%20Housing%20Board!5e1!3m2!1sen!2sin!4v1731261871617!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
		</div>
	</div>
	{/* <!--/ End Map Section -->
	<!-- Start Footer Area --> */}
	<Footer/>
        </>
    )
}
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../components/footer";
import Header from "../components/header";
import Preloader from "../components/preloader";
import nodatafound from "../images/nodata.png";

export default function SearchResultsPage() {
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const searchTerm = new URLSearchParams(location.search).get("query");
  console.log("Search Term: ", searchTerm); // Debugging line

  useEffect(() => {
    if (searchTerm) {
      setIsSearching(true);
      setError(null);
      axios
        .get("https://37h.4ad.mytemp.website/gsg/api/product")
        .then((response) => {
          // Filter results based on searchTerm
          const filteredResults = response.data.results.filter((product) => 
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
            product.description.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setResults(filteredResults);
        })
        .catch((err) => {
          console.error(err);
          setError("Failed to fetch search results.");
        })
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [searchTerm]);
  const handleNavigation = (product) => {
    // Navigate to viewproduct page and pass product details via state
    navigate(`/viewproduct/${product.id}`, { state: { product } });
  };

  return (
    <div className="container-fluid px-0">
      <Header/>
       {/* Breadcrumbs */}
            <div className="breadcrumbs">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="bread-inner">
                      <ul className="bread-list">
                        <li>
                          <Link to="/">
                          Back to Home &nbsp;| <i className="ti-arrow-right"></i>
                          </Link>
                        </li>
                        <li className="active"><a href="#">Search Results</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Breadcrumbs */}
    <div className="container search-results-page">
    {isSearching && <div className="search-loading d-flex align-items-center justify-content-center">
      <Preloader></Preloader>
      </div>}
    {error && <div className="error-message">{error}</div>}
    {searchTerm && results.length === 0 && (
      <div className="no-results no-results-container">
         <img src={nodatafound} alt="No Results Found" className="no-results-image" />
         <p className="no-results-text">Oops! No results found".</p>
      </div>
      // <div className="no-results">No results found for "{searchTerm}".</div>
    )}
    {results.length > 0 && (
      <div className="search-results d-flex flex-wrap">
        {results.map((product) => (
          <div className="col-xl-3 col-lg-4 col-md-4 col-12" key={product.id}>
          <div className="card single-product">
            <div className="product-img">
              <Link href="#" onClick={(e) => { e.preventDefault();
               handleNavigation(product);
               }}>
            <img className="card-img-top default-img" src={product.image} alt={product.name} />
            <img className="card-img-top hover-img" src={product.image} alt={product.name} />
            </Link>
            </div>
								 {/* Card Body: Product Information */}
    							<div className="card-body">
								<div className="card-title product-content">
                <Link href="#" onClick={(e) => { e.preventDefault();
               handleNavigation(product);
               }}>
									<h3>{product.name}</h3>
								<div className="product-price">
								<span>₹{product.price}</span>
								</div>
                </Link>
								</div>
                
								</div>
                    </div>
								</div>
								
							))}
      </div>
    )}
  </div>
  <Footer/>
  </div>
  );
}
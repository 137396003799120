import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "./header";

const ChangePassword = () => {
  const [apiError, setApiError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const navigate = useNavigate();
 const [userToken] = useState(localStorage.getItem("accessToken"));

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),

    newPassword: Yup.string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters")
      .notOneOf([Yup.ref("currentPassword")], "New password cannot be the same as the old password"),

    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setApiError("");
    setApiSuccess("");

    try {
      if (!userToken) {
        setApiError("Authentication token not found. Please log in.");
        return;
      }

      // API request
      const response = await axios.post(
        "https://37h.4ad.mytemp.website/gsg/api/user/change_password/",
        {
          old_password: values.currentPassword,
          new_password: values.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );

      if (response.status === 200) {
        setApiSuccess("Password changed successfully!");
        setTimeout(() => navigate("/"), 2000);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setApiError(error.response.data.error || "Password change failed.");
        } else {
          setApiError("Something went wrong. Please try again.");
        }
      }
    }

    setSubmitting(false);
  };

  return (
    <>
      <Header/>
      <div className="page-container">
        <div className="login-form-container">
          <h2 className="form-header">Change Password</h2>

          {apiError && <div className="form-error api-error">{apiError}</div>}
          {apiSuccess && <div className="form-success">{apiSuccess}</div>}

          <Formik
            initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="currentPassword" className="form-label">Current Password</label>
                  <Field type="password" id="currentPassword" name="currentPassword" className="form-input" placeholder="Enter current password" />
                  <ErrorMessage name="currentPassword" component="div" className="form-error" />
                </div>

                <div className="form-group">
                  <label htmlFor="newPassword" className="form-label">New Password</label>
                  <Field type="password" id="newPassword" name="newPassword" className="form-input" placeholder="Enter new password" />
                  <ErrorMessage name="newPassword" component="div" className="form-error" />
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
                  <Field type="password" id="confirmPassword" name="confirmPassword" className="form-input" placeholder="Confirm new password" />
                  <ErrorMessage name="confirmPassword" component="div" className="form-error" />
                </div>

                <button type="submit" className="form-submit-btn" disabled={isSubmitting}>
                  {isSubmitting ? "Changing..." : "Change Password"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import Preloader from "./preloader";
//images
import hp_product from "../images/hp.jpg";
import new_laptop2 from "../images/new-laptop2.webp";
import new_laptop4 from "../images/new-laptop4.jpg";
import new_asus from "../images/new-asus.jpg";
//bannerposter
import bannerposter1 from "../images/poster-1.jpeg";
import bannerposter2 from "../images/poster-2.webp";
//videos
import shop_video1 from "../videos/shop-vid1.mp4";
import shop_video2 from "../videos/shop-vid2.mp4";
import shop_video3 from "../videos/shop-vid3.mp4";
import shop_video4 from "../videos/shop-vid4.mp4";
import shop_video5 from "../videos/shop-vid5.mp4";
import Header from "./header";
import Footer from "./footer";
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Shopservices from "./shopservices";

export default function Homepage(){
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const { id } = useParams();
	const [error, setError] = useState(null);
	 const navigate = useNavigate();
	 const [banners, setBanners] = useState([]);
	 const [loading, setLoading] = useState(true);

  useEffect(() => {
	axios
	  .get(`https://37h.4ad.mytemp.website/gsg/api/product/`)
	  .then((response) => {
		setProducts(response.data.results);
		setError(null); // Clear any previous error
	  })
	  .catch((err) => {
		console.error("Error fetching products:", err);
		setError("Failed to load products. Please try again.");
	  });
  }, []);
  const handleNavigation = (product) => {
    // Navigate to viewproduct page and pass product details via state
    navigate(`/viewproduct/${product.id}`, { state: { product } });
  };

	 useEffect(() => {
    // Replace with your actual API endpoint
    axios.get('https://37h.4ad.mytemp.website/gsg/api/banner/')
      .then((response) => {
        setBanners(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching banners:', error);
		setLoading(false);
      });
  }, []);
  //Ratings
  const renderStars = (rating) => {
	const fullStar = "★";
	const halfStar = "⯪"; // Unicode for half star
	const emptyStar = "☆";
	let stars = "";
	for (let i = 1; i <= 5; i++) {
	  if (i <= Math.floor(rating)) {
		stars += fullStar;
	  } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
		stars += halfStar;
	  } else {
		stars += emptyStar;
	  }
	}
	return <span className="stars">{stars}</span>;
  };
   return(
      <>
	    <div class="overlay " data-overlay></div>
	<div className="container-xxl px-0">
		{/* <!-- Preloader --> */}
		<Preloader></Preloader>
{/* <!-- End Preloader --> */}
{/* <!-- Header --> */}
		<Header></Header>
		
{/* <!-- End Header --> */}
<section className="hero-slider">
{loading ? (
        <p>Loading banners...</p>
      ) : banners.length === 0 ? (
        <p>No banners available.</p>
      ) : (
      <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
		{banners.map((banner, index) => (
				 <div key={banner.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
				 <img src={banner.image} className="d-block w-100" alt={banner.name} />
			   </div>
			 ))}
        </div>

        {/* Carousel Controls */}
		<a className="carousel-control-prev" href="#carouselExample" role="button" data-bs-slide="prev">
			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
			<span className="visually-hidden">Previous</span>
		</a>
		<a className="carousel-control-next" href="#carouselExample" role="button" data-bs-slide="next">
			<span className="carousel-control-next-icon" aria-hidden="true"></span>
			<span className="visually-hidden">Next</span>
		</a>
      </div>
	     )}
    </section>
{/* <!--/ End Slider Area --> */}
{/* <!-- Start Product Area --> */}
	</div>
	<div className="product-area section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2>Our Products for Sales</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="product-info">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active">
                  <div className="row">
						  <div className="category-section">
							  <div className="row">
							  {products.length > 0 ? (
                  products.map((product) => (
                        <div className="col-xl-3 col-lg-4 col-md-4 col-12">
                          <div className="card single-product">
						  <Link href="#" onClick={(e) => { e.preventDefault();
											   handleNavigation(product);
											   }}>
                            <div className="product-img">
                              <a href="#">
                                <img
                                  className="card-img-top default-img"
                                  src={product.image}
                                  alt={product.name}
                                />
                                <img
                                  className="card-img-top hover-img"
                                  src={product.image}
                                  alt={product.name}
                                />
                              </a>
							   {/* Overlay Buttons */}
							   <div className="product-overlay">
                              <button className="view-product" to="/viewproduct"><i class="fa fa-eye" aria-hidden="true"></i>
							  </button>
                              {/* <button className="cart-btn">Add to Cart</button> */}
                            </div>
                            </div>
                            <div className="card-body">
                              <div className="card-title product-content">
								
                                <h3>{product.name}</h3>
                                <div className="product-price">
                                  <span>₹{product.price}</span>
                                </div>
							  {/* Ratings Section */}
                              <div className="product-ratings">{renderStars(product.rating)}</div>
                              </div>
                            </div>
							  </Link>
                            </div>
							
                          </div>
               ))
			) : (
			//   <p>No products available in this category.</p>
			<p><Preloader></Preloader></p>
			)}
                  </div>
                </div>
              </div>
			
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
	</div>
	{/* <!-- End Product Area -->
	<!-- Shop by videos - start --> */}
	<div className="container-fluid">
		<div className="row">
			<div className="col-12">
				<div className="section-title">
					<h2>Shop By Videos</h2>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12">
	<div className="video-carousel-container">
		<button className="carousel-arrow left" onclick="scrollCarousel(-1)">&lt;</button>
		
		<div className="video-carousel">
			{/* <!-- Thumbnails of videos in the carousel --> */}
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid1.mp4', 'Product Name 1','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.')">
				<video src={shop_video1} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid2.mp4', 'Product Name 2','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi,Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum. alias dolorum, earum velit voluptatum.')">
				<video src={shop_video2} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid3.mp4', 'Product Name 3','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.alias dolorum, earum velit voluptatum.')">
				<video src={shop_video3} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid4.mp4', 'Product Name 4','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi,Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum. alias dolorum, earum velit voluptatum.')">
				<video src={shop_video4} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid5.mp4', 'Product Name 5','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum,Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum. earum velit voluptatum.')">
				<video src={shop_video5} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid4.mp4', 'Product Name 4','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio!Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum. Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.')">
				<video src={shop_video4} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid3.mp4', 'Product Name 3','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibusLorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum. distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.')">
				<video src={shop_video3} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid4.mp4', 'Product Name 4','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.voluptatum.')">
				<video src={shop_video4} muted loop autoplay></video>
			</div>
			<div className="video-thumbnail" onclick="openModal('../videos/shop-vid4.mp4', 'Product Name 4','Rs.10000','Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sit fuga excepturi explicabo nobis eum repudiandae rerum optio! Iure temporibus sint voluptatibus distinctio totam eveniet eligendi, alias dolorum, earum velit voluptatum.voluptatum.')">
				<video src={shop_video4} muted loop autoplay></video>
			</div>
		</div>
		
		<button className="carousel-arrow right" onclick="scrollCarousel(1)">&gt;</button>
	</div>
	{/* <!-- Fullscreen Video Modal --> */}
<div id="videoModal" className="modal">
    <span className="close" onclick="closeModal()">&times;</span>
    <div className="modal-content">
        <div className="modal-video">
            <video id="modalVideo" autoplay muted loop></video>
        </div>
        <div className="modal-info">
            <div className="product-details">
                <h3 id="productTitle"></h3>
                <p id="productPrice"></p>
				<p id="productDesc"></p>
            </div>
            <div className="product-action">
                <button className="add-to-cart">View Product</button>
            </div>
        </div>
    </div>
</div>

			</div>
		</div>
	</div>

{/* <!-- Shop by videos - end -->
	<!-- Start Midium Banner  --> */}
	<section className="midium-banner">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="section-title">
						<h2>Brand New Arrivals</h2>
					</div>
				</div>
			</div>
			<div className="row">
				{/* <!-- Single Banner  --> */}
				<div className="col-lg-6 col-md-6 col-12">
					<div className="single-banner">
						<img src={bannerposter1} alt="#"></img>
						<div className="content">
							<p>Brand New Arrivals</p>
							<h3>Laptops <br></br>Up to<span> 20%</span></h3>
							<a href="#">Shop Now</a>
						</div>
					</div>
				</div>
				{/* <!-- /End Single Banner  --> */}
				{/* <!-- Single Banner  --> */}
				<div className="col-lg-6 col-md-6 col-12">
					<div className="single-banner">
						<img src={bannerposter2} alt="#"></img>
						<div className="content">
							<p>Brand New Arrivals</p>
							<h3>Mobiles <br></br> up to <span>20%</span></h3>
							<a href="#" className="btn">Shop Now</a>
						</div>
					</div>
				</div>
				{/* <!-- /End Single Banner  --> */}
			</div>
		</div>
	</section>
	{/* <!-- End Midium Banner -->
	
	{/*<!-- Start Shop Services Area --> */}
	<Shopservices/>

     {/* <!-- Start Footer Area --> */}
		<Footer></Footer>
	{/* <!-- End Footer Area --> */}
	{/* <Slidenav></Slidenav> */}
      </>
	  
   )

}
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "./cartcontext";
import Header from "../components/header";
import emptycart from "../images/empty-cart.svg";
import LoginRequiredPopup from "./modal_popup";
import Footer from "../components/footer";

const CartPage = () => {
  const { cartItems, updateCartItemQuantity, removeFromCart } = useCart();
  const [updatedCartItems, setUpdatedCartItems] = useState(cartItems);
  const [selectedItems, setSelectedItems] = useState([]); // Stores selected items for checkout
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [userToken, setUserToken] = useState(localStorage.getItem("accessToken"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  useEffect(() => {
    setUserToken(localStorage.getItem("accessToken"));
    setUserId(localStorage.getItem("userId"));
  }, []);

  useEffect(() => {
    setUpdatedCartItems(cartItems);
    localStorage.setItem("cart", JSON.stringify(cartItems));
      // Reset selection if cartItems change
      setSelectedItems((prevSelected) =>
        prevSelected.filter((selected) =>
          cartItems.some((item) => item.id === selected.id)
        )
      );
  }, [cartItems]);

  const handleQuantityChange = (event, productId) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity > 0) {
      updateCartItemQuantity(productId, newQuantity);
    }
  };

  const handleRemoveItem = (productId) => {
    removeFromCart(productId);
    setSelectedItems(selectedItems.filter((item) => item.id !== productId));
  };

  const handleCheckboxChange = (product) => {
    // setSelectedItems((prevSelected) =>
    //   prevSelected.some((selected) => selected.id === product.id)
    //     ? prevSelected.filter((selected) => selected.id !== product.id)
    //     : [...prevSelected, product]
    // );
    setSelectedItems((prevSelected) =>{
    const isSelected = prevSelected.some((item) => item.id === product.id);
    if (isSelected) {
      return prevSelected.filter((item) => item.id !== product.id);
    } else {
      return [...prevSelected, product];
    }
  });
  };
  const calculateSelectedTotal = () => {
    return selectedItems.reduce((total, item) => total + item.quantity * item.price, 0);
  };
  const handleCheckout = () => {
    if (!userToken || !userId) {
      setShowModal(true);
      return;
    }
    
    if (selectedItems.length === 0) {
      alert("Please select at least one item for checkout.");
      return;
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    navigate("/checkoutPage");
  };

  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bread-inner">
                <ul className="bread-list">
                  <li>
                    <Link to="/">Back to Home &nbsp; |<i className="ti-arrow-right"></i></Link>
                  </li>
                  <li className="active"><a href="javascript:void(0);">Cart Items</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-3">
        <div className="emptycart-cart-page">
          {updatedCartItems.length === 0 ? (
            <div className="empty-cart-container">
              <img src={emptycart} alt="Empty Cart" className="empty-cart-image" />
              <h2 className="empty-cart-title">Your Cart is <span className="empty-cart-highlight">Empty!</span></h2>
              <p className="empty-cart-message">Must add items to the cart before you proceed to checkout.</p>
              <Link to="/" className="empty-cart-button"><span> 🛍 RETURN TO SHOP</span></Link>
            </div>
          ) : (
            <>
            <div className="table-responsive">
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <th>Select</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {updatedCartItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedItems.some((selected) => selected.id === item.id)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </td>
                    <td>
                      <div className="d-flex align-items-center" style={{minWidth:"250px"}}>
                        <img src={item.image} alt={item.name} className="img-fluid rounded me-2" style={{ width: "50px", height: "50px" }} />
                        <span>{item.name}</span>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control w-25"
                        value={item.quantity}
                        min="1"
                        onChange={(e) => handleQuantityChange(e, item.id)}
                      />
                    </td>
                    <td>₹{item.price}</td>
                    <td>₹{item.quantity * item.price}</td>
                    <td>
                      <button className="btn btn-danger btn-sm" onClick={() => handleRemoveItem(item.id)}>
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
												   
									  
						  
							  
										 
																										 
																		
																							 
																	 
						  
            </table>
						  
																						  
										  
          </div>
          
																																
						   
											  
															  
																								
						 
					 
						
					  

              <div className="cart-summary">
                <p><strong>Selected Items Total: </strong> ₹{calculateSelectedTotal()}</p>
                <p><strong>Shipping: </strong> ₹0</p>
                <p><strong>Grand Total: </strong> ₹{calculateSelectedTotal()+ (selectedItems.length > 0 ? 0 : 0)}</p>
              </div>

              <div className="cart-actions">
                <Link to="/">Continue Shopping</Link>
                <button onClick={handleCheckout} className="bg-warning text-dark">Proceed to Checkout</button>
              </div>
            </>
          )}
        </div>
      </div>
    
      <Footer/>
      <LoginRequiredPopup show={showModal} setShow={setShowModal} />
    </>
  );
};

export default CartPage;


import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";

const ResetLinkSent = () => {
  return (
    <div className="reset-container">
      <div className="reset-box">
        {/* <faEnvelopeOpen className="email-icon" /> */}
        <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="email-icon"/>
        <h2 className="reset-link-title">Password Reset Link Sent</h2>
        <p className="reset-link-content">
          We’ve sent a password reset link to your registered email.
          Please check your inbox and follow the instructions.
        </p>
        <Link to="/login" className="reset-back-to-login">
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default ResetLinkSent;

import fastdelivery from "../images/fast-delivery.png";
import guarentee from "../images/guarantee-certificate.png";
import support24hrs from "../images/24-hours-support.png";
import returns from "../images/returns.png";

export default function Shopservices(){
    return(
        <>   
	{/* <!-- End Most Popular Area -->*/}
        {/* <!-- Start Shop Services Area -->  */}
	<section className="shop-services section home">
		<div className="container">
			<div className="row">
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<img src={fastdelivery} className="shopservices-img"></img>
						<h4>Fast & Secure Delivery</h4>
						<p>Get your orders delivered safely.</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
					<img src={returns} className="shopservices-img"></img>
						<h4>Free Return</h4>
						<p>Return in 2 Days, No Questions Asked</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
					<img src={guarentee} className="shopservices-img"></img>
						<h4>Money Back Guarentee</h4>
						<p>Within 10 Business Days</p>
					</div>
					{/* <!-- End Single Service --> */}
				</div>
				<div className="col-lg-3 col-md-6 col-12">
					{/* <!-- Start Single Service --> */}
					<div className="single-service">
						<img src={support24hrs} className="shopservices-img"></img>
						<h4>24x7 Service</h4>
						<p>Always Here for You – 24/7 Support!</p>
					</div>
				</div>
			</div>
		</div>
	</section>
        </>
    )
}